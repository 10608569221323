<template>
  <div>
    <ModalComponent
        title="Scan QR Code"
        color="warning"
        @update:show="handleEventModal"
        :show.sync="openScanQR"
    >
      <CRow v-if="openScanQR" class="text-center">
        <div class="w-100">
          <div>
            <qrcode-stream @decode="onDecode" @init="onInit" style="text-align: center">
              <div style="padding-top: 80px">
                <div v-if="result" style="color: #22ff34;font-size: 40px;">{{ result }}</div>
                <div v-if="resultError" class="text_result">{{ resultError }}</div>
              </div>
            </qrcode-stream>

          </div>
        </div>
      </CRow>
    </ModalComponent>

    <div class="text-sm-left mb-2">
      <CButton style="height: 80px" color="primary" class="mb-3" size="lg" @click="() => handleOpenScanQR(true)">
        QRコードをスキャンして登録する
      </CButton>
    </div>
    <vue-confirm-dialog></vue-confirm-dialog>
    <div>
      <CDataTable
          hover
          striped
          border
          :itemsPerPage="200"
          :items="itemMasters"
          :fields="fields"
      >
        <td slot="delete" slot-scope="{item, index}">
          <div>
            <CButtonClose @click="() => handleDelete(item)"/>
          </div>
        </td>
        <td slot="cost" slot-scope="{item}" class="text-right">
          <div>
            {{ item.cost }}
          </div>
        </td>
        <td slot="price" slot-scope="{item}" class="text-right">
          <div>
            {{ item.price }}
          </div>
        </td>
        <td slot="retail_price" slot-scope="{item}" class="text-right">
          <div>
            {{ item.retail_price }}
          </div>
        </td>
      </CDataTable>
    </div>
    <CRow class="text-center">
      <CCol md="6">
        <CButton style="height: 80px" color="success" @click="() => confirmChangeStatusCardboardItem()">
          上記内容で登録する
        </CButton>
      </CCol>
      <CCol md="6">
        <CButton class="mr-2" style="height: 80px" color="primary" @click="() => $router.back()">
          キャンセル
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'
import Vue from "vue";
import ModalComponent from "@/components/views/ModalComponent";
import apiCardboardOther from "@/api/cardboardApi";
import {numberWithCommas} from "@/utils/utils";


export default {

  components: {ModalComponent, QrcodeStream},

  data() {
    return {
      result: '',
      resultError: '',
      currentItemMaster: '',
      error: '',
      lastItemId: '',
      typeSelected: 1,
      itemMasters: [],
      itemIds: [],
      openScanQR: false,
      userSales: [],
      fields: [
        {key: 'status', label: 'ステータス'},
        {key: 'item_id', label: '商品ID'},
        {key: 'arrived_at', label: '入荷日'},
        {key: 'shelf', label: '棚名'},
        {key: 'name', label: '商品名'},
        {key: 'size', label: 'サイズ'},
        {key: 'color', label: '色'},
        {key: 'cost', label: '仕入原価'},
        {key: 'price', label: '販売価格'},
        {key: 'retail_price', label: 'プロパー上代'},
        {key: 'delete', label: '削除'},
      ],
      userIdDefault: ''
    }
  },

  created() {
    let user = this.$store.getters.user
    this.userIdDefault = user.id
  },

  methods: {
    confirmChangeStatusCardboardItem(){
      this.$confirm(
          {
            title: '確認',
            message: '検品済みとして登録しても宜しいですか？',
            button: {
              no: 'いいえ',
              yes: 'はい'
            },
            callback: confirm => {
              if (confirm) {
                // ... do something
                this.changeStatusCardboardItem()
              }
            }
          }
      )
    },
    changeStatusCardboardItem() {
      this.loading = true
      if(!this.itemMasters.length){
        Vue.$toast.error('少なくとも1つの商品が必要です。')
        return;
      }
      let params = {
        cardboard_items: this.itemMasters
      }
      apiCardboardOther.changeStatusCardboardItem(params).then(response => {
          Vue.$toast.success('検品が完了しました。');
        setTimeout(this.$router.back(), 1000);
      }).catch(error => {
        Vue.$toast.error('拒否されました。')
      }).finally(() =>{
        this.loading = false
      })

    },
    onDecode(result) {
      this.lastItemId = result
      if (!this.itemIds.includes(result)) {
        this.result = ''
        this.getItem(result)
        this.itemIds.push(result)
      }
    },

    handleEventModal(e, args, accept) {
      if (accept) {
        this.handleAddItem();
      } else {
        if (this.lastItemId) {
          let ids = this.itemIds;
          this.itemIds = ids.filter(i => i != this.lastItemId)
        }
        this.result = '';
        this.resultError = '';
        this.currentItemMaster = '';
      }
    },
    handleOpenScanQR(value) {
      this.openScanQR = value;
    },
    handleDelete(item) {
      this.itemMasters = this.itemMasters.filter(i => i.id !== item.id)
    },

    handleAddItem() {
      // let data = this.currentItemMaster;
      if (!this.currentItemMaster) {
        return ;
      }
      this.itemMasters = [];
      this.currentItemMaster.map(data => {
        let item = {
          status: data.status_name,
          cardboard_id: data.cardboard_id,
          id: data.id,
          item_id: data.item_id,
          arrived_at: data.item.stock.instock_date,
          shelf: data.item.stock.shelf.name,
          name: data.item.item_master.name,
          size: data.item.item_master.size,
          color: data.item.item_master.color,
          cost: numberWithCommas(data.item.item_master.cost),
          price: numberWithCommas(data.item.item_master.price),
          retail_price: numberWithCommas(data.item.item_master.retail_price)
        }
        if (!this.containsObject(item)) {
          this.itemMasters.push(item)
          this.result = ''
          this.resultError = ''
        } else {
          this.result = '';
          this.resultError = '製品が追加されました。';
        }
      })

      this.currentItemMaster = '';
    },

    getItem(cardboard_id) {
      // this.resetSelected()
      apiCardboardOther.itemScanQR({cardboard_id}).then(response => {
        let data = response.data;
        if (!data) {
          this.result = '';
          this.resultError = '段ボールを検品出来ません。段ボールが入荷予定か否か確認してください。';
          return
        }
        this.currentItemMaster = data;
        this.result = 'スキャンが完了しました。 \n 出荷ID：' + cardboard_id
        this.resultError = ''
      }).catch(error => {
        this.resultError = '商品が在庫にありません。'
      })
    },
    containsObject(obj) {
      let x = false;

      this.itemMasters.map(i => {
        if (i.id === obj.id) {
          x = true;
        }
      })

      return x;
    },
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      }
    }
  }
}
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}

.text_result {
  font-size: 40px;
  color: red;
}
</style>
